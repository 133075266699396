.ecw-applications-records {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
    font-size: 14px;
    line-height: 140%;

    tr {
        background-color: #ffffff;
    }

    thead tr:first-child {
        background-color: #2b92f1;
        font-weight: 500;
        color: #ffffff;
    }

    th,
    td {
        vertical-align: middle;
        padding: 12px 8px;
        margin: 0 0 12px 0;
    }

    :is(th, td):nth-child(2) {
        width: 30%;
    }

    :is(th, td):nth-child(5) {
        width: 20%;
    }
}
